<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('Launcher1CVersions')"
    @cancel="onCancel('Launcher1CVersions')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'Launcher1CVersionCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'Launcher1CVersions',
      pageHeader: 'Создание версии клиента 1C',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Версии клиента 1С',
          route: { name: 'Launcher1CVersions' },
        },
        {
          text: 'Создание версии клиента 1C',
        },
      ],
      initialForm: {},
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            { type: 'text', key: 'version', label: 'Версия' },
            { type: 'date', key: 'date', label: 'Дата' },
            { type: 'checkbox', key: 'isAdditional', label: 'Доп. клиент' },
            { type: 'text', key: 'link', label: 'Ссылка' },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
    }),
  },
};
</script>
